'use client';

import {spLogo} from '@/assets';
import {commonDisableCachingParameters, showErrorToast} from '@/common';
import {useRouter} from '@/hooks';
import {useCreateReviewMutation, useGetReceiverOrderQuery} from '@/services';
import {useLocale, useTranslations} from 'next-intl';
import Image from 'next/image';
import {useParams, useSearchParams} from 'next/navigation';
import {useEffect, useState} from 'react';
import {RatingSection} from '.';
import {CustomButton} from '../common';

const RatingForm = () => {
  const t = useTranslations();
  const locale = useLocale();
  const {orderNumber} = useParams();
  const searchParams = useSearchParams();
  const tokenParam = searchParams.get('token');
  const router = useRouter();

  const {data: order} = useGetReceiverOrderQuery(
    {
      order_number: orderNumber,
      receiver_auth_token: tokenParam,
      include: 'variants.product,shipping_address,drivers,shopping_method',
      locale,
    },
    commonDisableCachingParameters,
  );

  const [isClient, setIsClient] = useState(false);
  const [ratingsData, setRatingsData] = useState([]);
  const [createReview, {isLoading}] = useCreateReviewMutation();

  useEffect(() => {
    setIsClient(true);
  }, []);

  const handleRatingDataChange = (id, type, data) => {
    setRatingsData(prev =>
      prev.some(r => r.id === id && r.type === type)
        ? prev.map(r => (r.id === id && r.type === type ? {...r, ...data} : r))
        : [...prev, {id, type, ...data}],
    );
  };

  const handleSendAllRatings = async () => {
    const requests = ratingsData.map(({id, type, rate, comment}) =>
      createReview({
        review: {
          rating: rate,
          comment,
          reviewable_id: id,
          reviewable_type: `${type === 'Driver' ? 'Driver' : `Spree::${type}`}`,
          reciever_full_name: order?.shippingAddress?.[0]?.firstName,
          reciever_phone_number: order?.shippingAddress?.[0]?.phone,
        },
      }).unwrap(),
    );

    try {
      await Promise.all(requests);
      router.replace('/');
    } catch (error) {
      showErrorToast(t('rating_failed_please_try_again'));
    }
  };

  return (
    <div className="flex w-full justify-center">
      <div className="mx-4 mb-[6.5rem] bg-white py-2 sm:mb-[10.5rem] sm:w-[80vw] sm:p-16 sm:shadow-md xl:w-[60vw]">
        <Image src={spLogo} alt="logo" className="mb-8 h-16 w-16" />
        <p className="font-main text-[22px] font-medium text-persianIndigo">
          {t('rate_your_order')}
        </p>
        <p className="my-8 font-secondary text-[14px] font-normal text-persianIndigo sm:w-2/3">
          {t(
            'we_would_appreciate_it_if_you_took_some_of_your_time_to_rate_our_order_and_driver',
          )}
        </p>
        {order?.products?.length > 0 &&
          order.products.map((item, index) => (
            <div key={index} className="mb-[3.5rem]">
              <RatingSection
                title={`${t('rate_your')} ${item.name}`}
                reviewableId={item.id}
                reviewableType="Product"
                onChange={data =>
                  handleRatingDataChange(item.id, 'Product', data)
                }
              />
            </div>
          ))}
        {order?.isDelivery && (
          <div className="mb-[3.5rem]">
            <RatingSection
              title={t('rate_your_driver')}
              reviewableId={order?.driver?.[0]?.id}
              reviewableType="Driver"
              onChange={data =>
                handleRatingDataChange(order?.driver?.[0]?.id, 'Driver', data)
              }
            />
          </div>
        )}
        <div className="flex w-full justify-center">
          <CustomButton
            className="mt-4 w-[50%] rounded bg-royalPurple px-6 py-3 text-[18px] text-white"
            onClickHandling={handleSendAllRatings}
            value={t('send')}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default RatingForm;

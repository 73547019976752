'use client';

import {useState} from 'react';
import {useTranslations} from 'next-intl';
import {Input} from '@heroui/react';

const RatingSection = ({title, onChange}) => {
  const t = useTranslations();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [comment, setComment] = useState('');
  const [rate, setRate] = useState(null);

  const handleCircleClick = index => {
    setSelectedIndex(index);
    setRate(index + 1);
    onChange({rate: index + 1, comment});
  };

  const handleCommentChange = e => {
    const newComment = e.target.value;
    setComment(newComment);
    onChange({rate, comment: newComment});
  };

  return (
    <div>
      <p className="font-main text-[16px] font-medium text-persianIndigo sm:text-[20px] xl:text-[1.3vw]">
        {title}
      </p>
      <div className="my-6 flex flex-col items-center">
        <div className="flex w-full justify-center space-x-12">
          {Array.from({length: 5}).map((_, index) => (
            <div key={index} className="flex flex-col items-center">
              <div
                className={`flex h-10 w-10 cursor-pointer items-center justify-center rounded-full border-1 border-thistle pt-[3px] font-secondary text-[20px] font-normal text-persianIndigo ${
                  selectedIndex === index ? 'bg-royalPurple text-white' : ''
                }`}
                onClick={() => handleCircleClick(index)}>
                {index + 1}
              </div>
            </div>
          ))}
        </div>
      </div>
      <p className="font-secondary text-[14px] font-normal text-persianIndigo xl:text-[20px]">
        {t('write_comment')}
      </p>
      <Input
        type="text"
        onChange={handleCommentChange}
        value={comment}
        className="mb-8 self-center"
        classNames={{
          inputWrapper: [
            'border-[0.125px]',
            '!border-royalPurple',
            '!bg-white',
            '!pr-[0.5rem]',
            'h-[3.4rem]',
            'rounded-[0.6rem]',
          ],
          input: ['!text-persianIndigo'],
        }}
        size="md"
      />
    </div>
  );
};

export default RatingSection;

'use client';

import {phone_label} from '@/assets';
import {commonDisableCachingParameters, rules, showErrorToast} from '@/common';
import {useRouter} from '@/hooks';
import {getIsGuestPaymentStep} from '@/selectors';
import {
  useAddUserAddressMutation,
  useGetReceiverOrderQuery,
  useNextCheckoutStepMutation,
  useUpdateCheckoutMutation,
  useUpdateReceiverShippingAddressMutation,
} from '@/services';
import {Input, Radio, RadioGroup} from '@heroui/react';
import {sendGTMEvent} from '@next/third-parties/google';
import {useFormik} from 'formik';
import isEqual from 'lodash/isEqual';
import {useLocale, useTranslations} from 'next-intl';
import Image from 'next/image';
import {useParams, useSearchParams} from 'next/navigation';
import {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import * as Yup from 'yup';
import {MapView} from '.';
import {CustomButton} from '../common';

const ShippingAddressForm = ({buttonTitle, type, selectedAddress}) => {
  const t = useTranslations();
  const router = useRouter();
  const {orderNumber} = useParams();
  const searchParams = useSearchParams();
  const locale = useLocale();
  const userToken = searchParams.get('token');

  const [selected, setSelected] = useState('nationalAddress');
  const [formError, setFormError] = useState('');
  const [coords, setCoords] = useState({latitude: 24.7136, longitude: 46.6753});

  useEffect(() => {
    if (selectedAddress?.attributes?.address1 || type === 'receiverInfoForm') {
      setSelected('pickLocation');
    } else setSelected('nationalAddress');
  }, [selectedAddress, type]);

  const [updateShippingAddress] = useUpdateCheckoutMutation();
  const [updateReceiverShippingAddress] =
    useUpdateReceiverShippingAddressMutation();
  const token = useMemo(() => searchParams?.get('token'), [searchParams]);

  const {data: order, error: getReceiverOrderError} = useGetReceiverOrderQuery(
    {
      order_number: orderNumber,
      receiver_auth_token: token,
      include: 'shipping_address',
      locale,
    },
    {skip: type !== 'receiverInfoForm', ...commonDisableCachingParameters},
  );

  const [goToNextCheckOutStep] = useNextCheckoutStepMutation();

  const isGuestPaymentStep = useSelector(getIsGuestPaymentStep);

  const [addUserAddress] = useAddUserAddressMutation(undefined, {
    skip: isGuestPaymentStep,
  });

  const formik = useFormik({
    initialValues: {
      firstname: selectedAddress?.attributes?.firstname || '',
      lastname: 'N/A',
      phone: selectedAddress?.attributes?.phone?.replace(/^966/, '') || '',
      national_address: selectedAddress?.attributes?.national_address || '',
      building_number: selectedAddress?.attributes?.building_number || '',
      apartment_number: selectedAddress?.attributes?.apartment_number || '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      firstname: type === 'checkOut' ? rules('firstName', null, t) : null,
      phone: type === 'checkOut' ? rules('phoneNumber', null, t) : null,
      national_address: rules('nationalAddress', null, t),
      building_number: rules('aptnumber', null, t),
      apartment_number: rules('aptnumber', null, t),
    }),
    onSubmit: async values => {
      if (
        type !== 'checkOut' &&
        ((selected === 'nationalAddress' && !values.national_address) ||
          (selected === 'pickLocation' && !values.address1))
      ) {
        setFormError(t('please_enter_your_address'));
      } else if (selected === 'nationalAddress') {
        if (type === 'checkOut') {
          if (values.national_address) {
            const body = {
              firstname: values.firstname || 'N/A',
              lastname: values.lastname,
              phone: `966${values.phone}`,
              national_address: values.national_address || '',
            };
            const response = await updateShippingAddress({
              order: {
                ship_address_attributes: {
                  ...body,
                },
                bill_address_attributes: {
                  ...body,
                },
                ...(isGuestPaymentStep && {email: 'guest@example.com'}),
              },
            });
            if (values.phone && values.national_address && formik.dirty) {
              await addUserAddress({
                body: {
                  address: {...body},
                },
              });
            }
            setFormError('');
            if (type === 'checkOut' && !response.error) {
              await goToNextCheckOutStep();
              router.push('/checkout/3');
            } else if (response.error) {
              showErrorToast(response?.error.error);
            }
          } else {
            const body = {
              firstname: values.firstname || 'N/A',
              lastname: values.lastname,
              phone: `966${values.phone}`,
              country_iso: 'SA',
              city: 'SACity',
              address1: values.address1 || 'N/A',
            };
            const response = await updateShippingAddress({
              order: {
                ship_address_attributes: {
                  ...body,
                },
                bill_address_attributes: {
                  ...body,
                },
                ...(isGuestPaymentStep && {email: 'guest@example.com'}),
              },
            });
            if (values.phone && values.address1 && formik.dirty) {
              await addUserAddress({
                body: {
                  address: {...body},
                },
              });
            }
            setFormError('');
            if (type === 'checkOut' && !response.error) {
              await goToNextCheckOutStep();
              router.push('/checkout/3');
            } else if (response.error) {
              showErrorToast(response?.error.error);
            }
          }
        } else {
          const body = {
            firstname: order?.shippingAddress[0].firstName || 'N/A',
            phone: order?.shippingAddress[0].phone || 'N/A',
            lastname: order?.shippingAddress[0].lastName || values.lastname,
            national_address: values.national_address || '',
          };
          const response = await updateReceiverShippingAddress({
            order_number: orderNumber,
            receiver_auth_token: userToken,
            ship_address_attributes: {
              ...body,
            },
            bill_address_attributes: {
              ...body,
            },
          });
          setFormError('');
          if (!response.error) {
            router.push('/');
            sendGTMEvent({event: 'reciever_info_form_submit_trigger'});
          } else {
            showErrorToast(response?.error.error);
          }
        }
      } else {
        if (type === 'checkOut') {
          const response = await updateShippingAddress({
            order: {
              ship_address_attributes: {
                ...values,
                ...coords,
                address1: values.address1 || 'N/A',
                phone: `966${values.phone}`,
                firstname: values.firstname || 'N/A',
                country_iso: 'SA',
                city: 'SACity',
                zipcode: '90210',
              },
              bill_address_attributes: {
                ...values,
                ...coords,
                address1: values.address1 || 'N/A',
                phone: `966${values.phone}`,
                firstname: values.firstname || 'N/A',
                country_iso: 'SA',
                city: 'SACity',
              },
              ...(isGuestPaymentStep && {email: 'guest@example.com'}),
            },
          });
          const oldAddress = isEqual(values, {
            firstname: selectedAddress?.attributes?.firstname,
            lastname: 'N/A',
            phone: selectedAddress?.attributes?.phone?.replace(/^966/, ''),
            national_address:
              selectedAddress?.attributes?.national_address || '',
            building_number: selectedAddress?.attributes?.building_number,
            apartment_number: selectedAddress?.attributes?.apartment_number,
            address1: selectedAddress?.attributes?.address1,
            city: selectedAddress?.attributes?.city,
            country_iso: 'SA',
          });
          if (!oldAddress) {
            await addUserAddress({
              body: {
                address: {
                  ...values,
                  phone: `966${values.phone}`,
                  firstname: values.firstname || 'N/A',
                },
              },
            });
          }
          setFormError('');
          if (type === 'checkOut' && !response.error) {
            router.push('/checkout/3');
            await goToNextCheckOutStep();
          } else if (response.error) {
            showErrorToast(response?.error.error);
          }
        } else {
          const response = await updateReceiverShippingAddress(
            selected === 'nationalAddress'
              ? {
                  order_number: orderNumber,
                  receiver_auth_token: userToken,
                  ship_address_attributes: {
                    ...coords,
                    building_number: values.building_number,
                    apartment_number: values.apartment_number,
                    firstname: order?.shippingAddress[0].firstName || 'N/A',
                    phone: order?.shippingAddress[0].phone || 'N/A',
                    lastname:
                      order?.shippingAddress[0].lastName || values.lastname,
                    national_address: values.national_address,
                    zipcode: '90210',
                    country_id: 186,
                  },
                  bill_address_attributes: {
                    ...coords,
                    building_number: values.building_number,
                    apartment_number: values.apartment_number,
                    firstname: order?.shippingAddress[0].firstName || 'N/A',
                    phone: order?.shippingAddress[0].phone || 'N/A',
                    lastname:
                      order?.shippingAddress[0].lastName || values.lastname,
                    national_address: values.national_address,
                    zipcode: '90210',
                    country_id: 186,
                  },
                }
              : {
                  order_number: orderNumber,
                  receiver_auth_token: userToken,
                  ship_address_attributes: {
                    building_number: values.building_number,
                    apartment_number: values.apartment_number,
                    address1: values.address1,
                    city: values.city,
                    firstname: order?.shippingAddress[0].firstName || 'N/A',
                    phone: order?.shippingAddress[0].phone || 'N/A',
                    last_name:
                      order?.shippingAddress[0].lastName || values.lastname,
                    zipcode: '90210',
                    country_id: 186,
                    ...coords,
                  },
                  bill_address_attributes: {
                    building_number: values.building_number,
                    apartment_number: values.apartment_number,
                    address1: values.address1,
                    city: values.city,
                    firstname: order?.shippingAddress[0].firstName || 'N/A',
                    phone: order?.shippingAddress[0].phone || 'N/A',
                    last_name: order?.shippingAddress[0].lastName || 'N/A',
                    zipcode: '90210',
                    country_id: 186,
                    ...coords,
                  },
                },
          );
          setFormError('');
          if (!response.error) {
            router.push('/');
            sendGTMEvent({event: 'reciever_info_form_submit_trigger'});
          } else if (response.error) {
            showErrorToast(response?.error.error);
          }
        }
      }
    },
  });

  const handleNationalAddressInputChange = e => {
    if (formError) {
      setFormError('');
    }
    formik.handleChange(e);
  };

  const handleLocationSelected = () => {
    setFormError('');
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <>
        {type === 'checkOut' ? (
          <div className="flex items-center justify-between">
            <div className="mb-4 w-[47.5%]">
              <Input
                type="text"
                variant="underlined"
                name="firstname"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstname}
                placeholder={t('first_name')}
                className="font-secondary text-[20px] font-normal text-persianIndigo"
                classNames={{
                  label: '!text-thistle',
                  inputWrapper:
                    '!border-thistle hover:border-thistle focus:border-thistle focus:border-[5px] !outline-0 after:bg-persianIndigo',
                  input:
                    'placeholder:font-secondary placeholder:text-[16px] placeholder:font-normal placeholder:text-thistle !text-persianIndigo',
                }}
              />
              {formik.touched.firstname && formik.errors.firstname ? (
                <div className="mt-2 font-secondary text-[12px] text-red-500">
                  {formik.errors.firstname}
                </div>
              ) : null}
            </div>

            <div className="mb-4 w-[47.5%] font-secondary text-persianIndigo">
              <Input
                type="text"
                variant="underlined"
                name="phone"
                startContent={
                  <div
                    className={`${locale === 'ar' ? 'ml-2' : 'mr-2'} flex w-[7.3rem] items-center gap-1 md:w-[6.3rem]`}>
                    <Image
                      width={30}
                      height={10}
                      src={phone_label}
                      alt="phone label"
                    />
                    <p className="text-[14px] text-persianIndigo">+966</p>
                  </div>
                }
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                classNames={{
                  label: '!text-thistle',
                  inputWrapper:
                    '!border-thistle hover:border-thistle focus:border-thistle focus:border-[5px] !outline-0 after:bg-persianIndigo',
                  input:
                    'placeholder:font-secondary placeholder:text-[16px] placeholder:font-normal placeholder:text-thistle !text-persianIndigo',
                }}
              />
              {formik.touched.phone && formik.errors.phone ? (
                <div className="mt-2 font-secondary text-[12px] text-red-500">
                  {formik.errors.phone}
                </div>
              ) : null}
            </div>
          </div>
        ) : null}

        <div className="mt-4 flex flex-col">
          {type === 'checkOut' ? (
            <RadioGroup
              value={selected}
              onValueChange={setSelected}
              color="secondary"
              className="mb-4"
              classNames={{}}>
              <Radio
                className="!pb-4 font-secondary text-[18px] font-normal !text-persianIndigo"
                value="nationalAddress">
                {type !== 'checkOut' ? (
                  <span className="text-persianIndigo">
                    {t('national_address')}
                  </span>
                ) : (
                  <span className="text-persianIndigo">
                    {t('proceed_without_providing_an_address')}
                  </span>
                )}
              </Radio>
              {selected === 'nationalAddress' && type !== 'checkOut' && (
                <div className="mb-8">
                  <Input
                    type="text"
                    variant="underlined"
                    name="national_address"
                    onChange={handleNationalAddressInputChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.national_address}
                    placeholder={t('write_your_national_address')}
                    className="font-secondary text-[20px] font-normal text-persianIndigo"
                    classNames={{
                      label: '!text-thistle',
                      inputWrapper:
                        '!border-thistle hover:border-thistle focus:border-thistle focus:border-[5px] !outline-0 after:bg-persianIndigo',
                      input:
                        'placeholder:font-secondary placeholder:text-[16px] placeholder:font-normal placeholder:text-thistle !text-persianIndigo',
                    }}
                  />
                  {formik.touched.national_address &&
                  formik.errors.national_address ? (
                    <div className="mt-2 font-secondary text-[12px] text-red-500">
                      {formik.errors.national_address}
                    </div>
                  ) : null}
                </div>
              )}
              <Radio
                className="!pt-4 font-secondary text-[18px] font-normal text-persianIndigo"
                classNames={{
                  label: '!p-0',
                }}
                value="pickLocation">
                <span className="text-persianIndigo">
                  {t('pick_your_location')}
                </span>
              </Radio>
              {selected === 'pickLocation' && (
                <>
                  <MapView
                    selected={selected}
                    formik={formik}
                    onLocationSelected={handleLocationSelected}
                    setCoords={setCoords}
                    type={type}
                    selectedAddress={selectedAddress}
                  />
                  {formik.values.address1 && (
                    <div className="mb-4 font-secondary text-[14px] text-persianIndigo">
                      {t('selected_address')}: {formik.values.address1}
                    </div>
                  )}
                </>
              )}
            </RadioGroup>
          ) : (
            <>
              <span className="py-4 font-secondary text-[18px] font-normal text-persianIndigo">
                {t('pick_your_location')}
              </span>
              {selected === 'pickLocation' && (
                <div className="mb-4">
                  <MapView
                    selected={selected}
                    formik={formik}
                    onLocationSelected={handleLocationSelected}
                    setCoords={setCoords}
                    type={type}
                    selectedAddress={selectedAddress}
                  />
                  {formik.values.address1 && (
                    <div className="font-secondary text-persianIndigo">
                      <span className="text-[16px] font-bold">
                        {t('selected_address')}:{' '}
                      </span>
                      <span className="text-[14px]">
                        {formik.values.address1}
                      </span>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>

        {formError && (
          <div className="mb-2 font-secondary text-[12px] text-red-500">
            {formError}
          </div>
        )}

        {selected === 'pickLocation' && (
          <>
            <div className="mb-4">
              <Input
                type="text"
                variant="underlined"
                name="building_number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.building_number}
                placeholder={t('building_number')}
                className="font-secondary text-[20px] font-normal text-persianIndigo"
                classNames={{
                  label: '!text-thistle',
                  inputWrapper:
                    '!border-thistle hover:border-thistle focus:border-thistle focus:border-[5px] !outline-0 after:bg-persianIndigo',
                  input:
                    'placeholder:font-secondary placeholder:text-[16px] placeholder:font-normal placeholder:text-thistle !text-persianIndigo',
                }}
              />
            </div>

            <div className="mb-4">
              <Input
                type="text"
                variant="underlined"
                name="apartment_number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.apartment_number}
                placeholder={t('apt_number')}
                className="font-secondary text-[20px] font-normal text-persianIndigo"
                classNames={{
                  label: '!text-thistle',
                  inputWrapper:
                    '!border-thistle hover:border-thistle focus:border-thistle focus:border-[5px] !outline-0 after:bg-persianIndigo',
                  input:
                    'placeholder:font-secondary placeholder:text-[16px] placeholder:font-normal placeholder:text-thistle !text-persianIndigo',
                }}
              />
            </div>
          </>
        )}

        <CustomButton
          btnStyles="w-full mb-4 h-[52px]"
          btnActionType="submit"
          value={t(buttonTitle)}
          disabled={formik.isSubmitting}
          isLoading={formik.isSubmitting}
        />
      </>
    </form>
  );
};

export default ShippingAddressForm;

'use client';

import {useTranslations} from 'next-intl';
import {ShippingAddressForm} from '.';
import Image from 'next/image';
import {spLogo} from '@/assets';

const ReceiverInfoForm = () => {
  const t = useTranslations();

  return (
    <div className="flex w-full justify-center">
      <div className="mx-4 mb-[6.5rem] bg-white py-2 sm:mb-[10.5rem] sm:w-[80vw] sm:p-16 sm:shadow-md xl:w-[60vw]">
        <Image src={spLogo} alt="logo" className="mb-8 h-16 w-16" />
        <p className="font-main text-[22px] font-medium text-persianIndigo">
          {t('please_fill_in_fields_below')}
        </p>
        <p className="my-8 font-secondary text-[14px] font-normal text-persianIndigo sm:w-2/3">
          {t(
            'a_loved_one_has_bought_you_flowers_please_fill_in_the_fields_below_to_complete_the_delivery_details',
          )}
        </p>
        <ShippingAddressForm type={'receiverInfoForm'} buttonTitle={'done'} />
      </div>
    </div>
  );
};

export default ReceiverInfoForm;

'use client';

import {minus, plus} from '@/assets';
import {showErrorToast} from '@/common';
import {
  APIProvider,
  ControlPosition,
  Map,
  MapControl,
} from '@vis.gl/react-google-maps';
import {useLocale, useTranslations} from 'next-intl';
import Image from 'next/image';
import {useEffect, useState} from 'react';
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  geocodeByLatLng,
  geocodeByPlaceId,
  getLatLng,
} from 'react-google-places-autocomplete';
import {BiCurrentLocation} from 'react-icons/bi';
import {FaMapMarkerAlt, FaSearch} from 'react-icons/fa';

const MapView = ({
  selected,
  formik,
  onLocationSelected,
  setCoords,
  type,
  selectedAddress,
}) => {
  const t = useTranslations();
  const locale = useLocale();
  const [selectedCoordinates, setSelectedCoordinates] = useState({
    lat: 24.7136,
    lng: 46.6753,
  });
  const [place, setPlace] = useState(null);
  const [zoom, setZoom] = useState(10);

  useEffect(() => {
    if (type === 'receiverInfoForm') {
      locateUser();
    }
  }, [type]);

  useEffect(() => {
    const fetchAddress = async () => {
      const results = await geocodeByLatLng({
        lat: selectedCoordinates.lat,
        lng: selectedCoordinates.lng,
      });
      if (results && results.length > 0 && selected === 'pickLocation') {
        const {address, city} = extractAddressAndCity(results);
        formik.setFieldValue('address1', address);
        formik.setFieldValue('city', city);
        formik.setFieldValue(
          type === 'checkOut' ? 'country_iso' : 'country_id',
          type === 'checkOut' ? 'SA' : 186,
        );
      }
    };
    if (selectedAddress?.attributes?.address1) {
      google.maps.importLibrary('geocoding').then(() => {
        geocodeByAddress(selectedAddress?.attributes?.address1)
          .then(results => getLatLng(results[0]))
          .then(({lat, lng}) => {
            setSelectedCoordinates({lat, lng});
            if (setCoords) {
              setCoords({latitude: lat, longitude: lng});
            }
            formik.setFieldValue(
              'address1',
              selectedAddress?.attributes?.address1,
            );
            formik.setFieldValue('city', selectedAddress?.attributes?.city);
            formik.setFieldValue(
              type === 'checkOut' ? 'country_iso' : 'country_id',
              type === 'checkOut' ? 'SA' : 186,
            );
          })
          .catch(error => showErrorToast('Error geocoding address:', error));
      });
    } else {
      fetchAddress();
    }
  }, [selectedAddress]);

  const handleZoomIn = () => {
    setZoom(prevZoom => prevZoom + 1);
  };

  const handleZoomOut = () => {
    setZoom(prevZoom => prevZoom - 1);
  };

  const extractAddressAndCity = results => {
    const address = results[0].formatted_address || 'N/A';
    const addressComponents = results[0].address_components;
    const cityComponent = addressComponents.find(
      component =>
        component.types.includes('locality') ||
        component.types.includes('administrative_area_level_2'),
    );
    const city = cityComponent ? cityComponent.long_name : 'SACity';
    return {address, city};
  };

  const handleLocationChange = (address, city, lat, lng) => {
    formik.setFieldValue('address1', address);
    formik.setFieldValue('city', city);
    formik.setFieldValue(
      type === 'checkOut' ? 'country_iso' : 'country_id',
      type === 'checkOut' ? 'SA' : 186,
    );
    setSelectedCoordinates({lat, lng});
    if (setCoords) {
      setCoords({latitude: lat, longitude: lng});
    }
    onLocationSelected();
  };

  const handleMapClick = async event => {
    const lat = event.detail?.latLng?.lat;
    const lng = event.detail?.latLng?.lng;

    const results = await geocodeByLatLng({lat, lng});
    if (results && results.length > 0 && selected === 'pickLocation') {
      const {address, city} = extractAddressAndCity(results);
      handleLocationChange(address, city, lat, lng);
    }
  };

  const handleChange = async choice => {
    if (choice && selected === 'pickLocation') {
      const results = await geocodeByPlaceId(choice?.value?.place_id);
      const {address, city} = extractAddressAndCity(results);
      const {lat, lng} = (await getLatLng(results[0])) || {
        lat: 'N/A',
        lng: 'N/A',
      };
      handleLocationChange(address, city, lat, lng);
      setPlace(choice);
    }
  };

  const handleMarkerDragging = async (lat, lng) => {
    const results = await geocodeByLatLng({lat, lng});
    if (results && results.length > 0 && selected === 'pickLocation') {
      const {address, city} = extractAddressAndCity(results);
      handleLocationChange(address, city, lat, lng);
    }
  };

  const handlingMapDragging = (lat, long) => {
    handleMarkerDragging(lat, long);
  };

  const handleMapRef = position => {
    handlingMapDragging(position.map.center.lat(), position.map.center.lng());
  };

  const locateUser = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async position => {
          const {latitude, longitude} = position.coords;
          const results = await geocodeByLatLng({
            lat: latitude,
            lng: longitude,
          });
          if (results && results.length > 0 && selected === 'pickLocation') {
            const {address, city} = extractAddressAndCity(results);
            handleLocationChange(address, city, latitude, longitude);
          }
        },
        error => {
          showErrorToast(t('unable_to_fetch_location'));
        },
        {enableHighAccuracy: true},
      );
    } else {
      showErrorToast(t('geolocation_not_supported'));
    }
  };

  const CustomZoomControls = ({onZoomIn, onZoomOut}) => {
    return (
      <div className="absolute bottom-[12px] right-[18px] z-[1]">
        <button
          onClick={onZoomIn}
          className={`${locale === 'ar' ? 'ml-2' : 'mr-2'}`}>
          <Image className="h-[20px] w-[20px]" src={plus} alt="plus" />
        </button>
        <button onClick={onZoomOut}>
          <Image src={minus} className="h-[20px] w-[20px]" alt="minus" />
        </button>
      </div>
    );
  };

  return (
    <APIProvider
      apiKey={`${process.env.NEXT_PUBLIC_REACT_APP_MAPS_API_KEY}`}
      libraries={['places']}>
      <Map
        mapId="DEMO_MAP_ID"
        className="h-[50vh] w-full"
        scaleControl={false}
        onClick={handleMapClick}
        onDragend={handleMapRef}
        disableDefaultUI={true}
        zoomControl={false}
        zoom={zoom}
        center={selectedCoordinates}>
        <MapControl position={ControlPosition.TOP_RIGHT}>
          <div className="relative w-[12rem]">
            <FaSearch className="absolute left-[10px] top-[50%] z-[1] translate-y-[-50%] text-[#aaa]" />
            <GooglePlacesAutocomplete
              apiKey={`${process.env.NEXT_PUBLIC_REACT_APP_MAPS_API_KEY}`}
              selectProps={{
                isClearable: false,
                isSearchable: true,
                value: place,
                onChange: handleChange,
                placeholder: t('search'),
                noOptionsMessage: () => t('no_address_found'),
                loadingMessage: () => `${t('loading')}...`,
                styles: {
                  container: baseStyles => ({
                    ...baseStyles,
                    width: '12rem',
                  }),
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: 'transparent',
                    borderRadius: '16px',
                    padding: '0 0 0 20px',
                    margin: '10px 10px 0 0',
                  }),
                  dropdownIndicator: baseStyles => ({
                    ...baseStyles,
                    opacity: '0',
                  }),
                  indicatorSeparator: baseStyles => ({
                    ...baseStyles,
                    opacity: '0',
                  }),
                  menu: baseStyles => ({
                    ...baseStyles,
                    borderRadius: '10px',
                    width: '11.4rem',
                  }),
                },
              }}
              autocompletionRequest={{
                componentRestrictions: {
                  country: ['sa'],
                },
              }}
              debounce={1000}
            />
          </div>
        </MapControl>
        <div>
          <CustomZoomControls
            onZoomIn={handleZoomIn}
            onZoomOut={handleZoomOut}
          />
        </div>
        <MapControl position={ControlPosition.CENTER}>
          <FaMapMarkerAlt color="#814EAB" size={35} />
        </MapControl>
        <MapControl position={ControlPosition.BOTTOM_LEFT}>
          <button
            type="button"
            onClick={locateUser}
            className="mb-2 flex items-center justify-center rounded-full bg-white shadow-md">
            <BiCurrentLocation size={30} color="#814EAB" />
          </button>
        </MapControl>
      </Map>
    </APIProvider>
  );
};

export default MapView;
